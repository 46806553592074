import React, { useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { withFormik, FieldArray } from 'formik';
import { Formik } from 'formik';
import { withStyles, Typography } from '@material-ui/core';
import * as Yup from 'yup';
import Card from '@material-ui/core/Card';
import Checkbox from '@material-ui/core/Checkbox';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import Button from '@material-ui/core/Button';
import DashboardHOC from '../../components/DashboardHOC';
import LoadingIndicator from '../../components/LoadingIndicator';
import { convertFileToBase64, getBase64ImageFromUrl } from '../../utils/utils';
import { createDeviceAction, updateDeviceAction, resetCreatedDevice, getDeviceAction } from './state/devices-actions';
import InputField from '../../components/InputField';
import HeaderWithButton from '../../components/HeaderWithButton';
import ErrorAlert from '../../components/ErrorAlert';

import { readZipFile } from '../../utils/zipUtils';

import styles from './DeviceEditPage.styles';

const type = [
  { value: 'JEWELRY', label: 'Jewelry' },
  { value: 'WATCH_OR_WRISTBAND', label: 'Watch or Wristband' },
  { value: 'TAG', label: 'Tag' }
];

const Form = (props) => {
  const dispatch = useDispatch();
  const stableDispatch = useCallback(dispatch, []);

  const { devicesState, resetDevice, match } = props;
  const { processing, deviceData, processingUpdateDevice, errorUpdateDevice, error, registeredVariants } = devicesState;
  const { params } = match;

  let history = useHistory();

  const [errorImportDevice, setErrorImportDevice] = useState();
  const [readOnly, setReadOnly] = useState(false);

  useEffect(() => {
    if (!deviceData) {
      stableDispatch(getDeviceAction(params.brand, params.model, params.version));
    }
  }, [deviceData, params, stableDispatch]);

  useEffect(() => {
    if (devicesState.updatedDeviceData) {
      const { brand, model } = devicesState.updatedDeviceData;
      history.replace(`/dashboard/device/brand/${brand}/model/${model.name}/version/${model.version}`);
      resetDevice();
    }
  }, [devicesState.updatedDeviceData, resetDevice, history]);

  const { classes, isSubmitting } = props;

  const isValidUrl = (url) => {
    try {
      const validUrl = new URL(url);
      return validUrl.protocol === 'https:' || validUrl.protocol === 'http:';
    } catch (e) {
      console.log({ e });
      return false;
    }
  };

  return (
    <DashboardHOC>
      {errorImportDevice && <ErrorAlert title={'Error importing device from file'} error={errorImportDevice} />}
      {!error && deviceData && !processing ? (
        <div>
          <HeaderWithButton headerText="Edit Device" />
          <Formik
            initialValues={{
              entityId: deviceData?.entityId,
              brand: deviceData?.brand,
              defaultImage: deviceData?.defaultImage,
              model: deviceData?.modelDisplayName,
              version: deviceData?.model?.version,
              nfcType: 'PASSIVE',
              tdpId: parseInt(deviceData?.tdpId),
              tdpIdentifier: deviceData?.tdpIdentifier,
              type: deviceData?.type,
              typeCode: deviceData?.typeCode,
              variants: deviceData?.variants ? deviceData.variants : [],
              description: deviceData?.description || '',
              storeLink: deviceData?.storeLink || '',
              brandMobileVideoTrailer: deviceData?.brandMobileVideoTrailer || '',
              excludedFromWebsite: deviceData?.excludedFromWebsite,
              featuredOnWebsite: deviceData?.featuredOnWebsite,
              rcosNotSupported: deviceData?.rcosNotSupported,
              tokenisationNotSupported: deviceData?.tokenisationNotSupported,
              promoReadyNotSupported: deviceData?.promoReadyNotSupported
            }}
            validationSchema={Yup.object().shape({
              model: Yup.string().required('Required'),
              version: Yup.string().required('Required'),
              defaultImage: Yup.string().required('Default image is required!'),
              tdpId: Yup.number('Tdp must be a number').required('Tdp is required'),
              tdpIdentifier: Yup.string().required('Tdp Identifier is required'),
              type: Yup.string().required('Select your type category'),
              variants: Yup.array().of(
                Yup.object().shape({
                  color: Yup.string().required('description required'),
                  image: Yup.string().required('image required')
                })
              ),
              storeLink: Yup.string().test('is-url-valid', 'URL is not valid', (value) => !value || isValidUrl(value)),
              brandMobileVideoTrailer: Yup.string(),
              typeCode: Yup.string()
            })}
            onSubmit={async (values, { setSubmitting }) => {
              const {
                entityId,
                brand,
                model,
                version,
                defaultImage,
                nfcType,
                tdpId,
                tdpIdentifier,
                type,
                typeCode,
                variants,
                description,
                storeLink,
                brandMobileVideoTrailer,
                excludedFromWebsite,
                featuredOnWebsite,
                rcosNotSupported,
                tokenisationNotSupported,
                promoReadyNotSupported
              } = values;

              let variantsWithBase64Imgs;

              if (variants?.length > 0) {
                variantsWithBase64Imgs = await Promise.all(
                  variants.map(async (variant) => {
                    const base64VariantImg = variant.image.includes('https://')
                      ? await getBase64ImageFromUrl(variant.image)
                      : variant.image;
                    return {
                      color: variant.color,
                      image: base64VariantImg
                    };
                  })
                );
              }

              const deviceModel = {
                entityId,
                brand,
                defaultImage,
                model: { name: model, version },
                nfcType,
                tdpId,
                tdpIdentifier,
                type,
                typeCode,
                variants: variants.length > 0 ? variantsWithBase64Imgs : null,
                description,
                storeLink,
                brandMobileVideoTrailer,
                excludedFromWebsite,
                featuredOnWebsite,
                rcosNotSupported,
                tokenisationNotSupported,
                promoReadyNotSupported
              };
              dispatch(updateDeviceAction(brand, model, version, deviceModel));
              setSubmitting(false);
            }}
          >
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, handleReset, setFieldValue }) => {
              if (processingUpdateDevice) {
                return <LoadingIndicator title="Updating device details" />;
              }

              return (
                <form onSubmit={handleSubmit}>
                  <Card className={classes.card}>
                    <CardContent>
                      <>
                        <input
                          id="myInput"
                          type="file"
                          style={{ opacity: 0 }}
                          onChange={(event) => {
                            setErrorImportDevice(null);
                            readZipFile(
                              event,
                              { oem: match.params.brand, deviceModel: match.params.model, deviceVersion: match.params.version },
                              {
                                setErrorImportDevice,
                                setFieldValue,
                                setReadOnly
                              }
                            );
                          }}
                        />
                        <label htmlFor="myInput">
                          <Button color="primary" aria-label="upload picture" component="span" variant="outlined">
                            Import Device from File
                          </Button>
                        </label>
                      </>
                      <InputField
                        id="entityId"
                        label="Entity Id"
                        value={values.entityId}
                        disabled
                        margin="dense"
                        variant="outlined"
                        fullWidth
                      />
                      <InputField id="brand" label="Brand" value={values.entityId} disabled margin="dense" variant="outlined" fullWidth />
                      <InputField id="model" label="Model" value={values.model} disabled margin="dense" variant="outlined" fullWidth />
                      <InputField
                        id="version"
                        label="Version"
                        value={values.version}
                        disabled
                        margin="dense"
                        variant="outlined"
                        fullWidth
                      />
                      <InputField
                        id="tdpId"
                        label="TDP ID"
                        type="tdpId"
                        value={values.tdpId}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={touched.tdpId ? errors.tdpId : ''}
                        error={touched.tdpId && Boolean(errors.tdpId)}
                        margin="dense"
                        variant="outlined"
                        fullWidth
                      />
                      <InputField
                        id="tdpIdentifier"
                        label="TDP Identifier"
                        type="tdpIdentifier"
                        value={values.tdpIdentifier}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={touched.tdpIdentifier ? errors.tdpIdentifier : ''}
                        error={touched.tdpIdentifier && Boolean(errors.tdpIdentifier)}
                        margin="dense"
                        variant="outlined"
                        fullWidth
                      />
                      <InputField
                        id="description"
                        label="Description"
                        type="description"
                        value={values.description}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={touched.description ? errors.description : ''}
                        error={touched.description && Boolean(errors.description)}
                        margin="dense"
                        variant="outlined"
                        fullWidth
                      />
                      <InputField
                        id="storeLink"
                        label="Store url"
                        type="storeLink"
                        value={values.storeLink}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={touched.storeLink ? errors.storeLink : ''}
                        error={touched.storeLink && Boolean(errors.storeLink)}
                        margin="dense"
                        variant="outlined"
                        fullWidth
                      />
                      <InputField
                        id="brandMobileVideoTrailer"
                        label="Video trailer url"
                        type="brandMobileVideoTrailer"
                        value={values.brandMobileVideoTrailer}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={touched.brandMobileVideoTrailer ? errors.brandMobileVideoTrailer : ''}
                        error={touched.brandMobileVideoTrailer && Boolean(errors.brandMobileVideoTrailer)}
                        margin="dense"
                        variant="outlined"
                        fullWidth
                      />
                      <InputField
                        select
                        id="type"
                        label="Type"
                        value={values.type}
                        onChange={handleChange('type')}
                        helperText={touched.type ? errors.type : ''}
                        error={touched.type && Boolean(errors.type)}
                        margin="dense"
                        variant="outlined"
                        fullWidth
                      >
                        {type.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </InputField>
                      <InputField
                        id="typeCode"
                        label="Type Code"
                        type="typeCode"
                        value={values.typeCode}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={touched.typeCode ? errors.typeCode : ''}
                        error={touched.typeCode && Boolean(errors.typeCode)}
                        margin="dense"
                        variant="outlined"
                        fullWidth
                      />
                      <Typography gutterBottom>
                        Rcos NOT supported{' '}
                        <Checkbox
                          checked={values.rcosNotSupported}
                          onChange={handleChange}
                          id="rcosNotSupported"
                          value={values.rcosNotSupported}
                        />
                      </Typography>
                      <Typography gutterBottom>
                        Tokenisation NOT supported{' '}
                        <Checkbox
                          checked={values.tokenisationNotSupported}
                          onChange={handleChange}
                          id="tokenisationNotSupported"
                          value={values.tokenisationNotSupported}
                        />
                      </Typography>
                      <Typography gutterBottom>
                        Promo Ready NOT supported{' '}
                        <Checkbox
                          checked={values.promoReadyNotSupported}
                          onChange={handleChange}
                          id="promoReadyNotSupported"
                          value={values.promoReadyNotSupported}
                        />
                      </Typography>
                      <Typography gutterBottom>
                        Excluded from website{' '}
                        <Checkbox
                          checked={values.excludedFromWebsite}
                          onChange={handleChange}
                          id="excludedFromWebsite"
                          value={values.excludedFromWebsite}
                        />
                      </Typography>
                      <Typography gutterBottom>
                        Featured on website{' '}
                        <Checkbox
                          checked={values.featuredOnWebsite}
                          onChange={handleChange}
                          id="featuredOnWebsite"
                          value={values.featuredOnWebsite}
                        />
                      </Typography>
                      <div className={classes.defaultImageUpload}>
                        <Typography variant="body1" gutterBottom>
                          Default Image
                        </Typography>
                        <input
                          name="file"
                          accept="image/*"
                          multiple={false}
                          onChange={async (event) => {
                            const file = event.currentTarget.files[0];
                            const base64Image = await convertFileToBase64(file);
                            setFieldValue(`defaultImage`, base64Image);

                            setFieldValue(`defaultImagePreviewUrl`, URL.createObjectURL(file));
                          }}
                          className={classes.input}
                          id="defaultImage"
                          type="file"
                        />
                        {values.defaultImage.includes('http') && (
                          <div>
                            <img alt="" className={classes.image} src={`${values.defaultImage}?${performance.now()}`} />
                          </div>
                        )}

                        {values.defaultImagePreviewUrl && (
                          <div>
                            <img alt="" className={classes.image} src={values.defaultImagePreviewUrl} />
                          </div>
                        )}
                        <div>
                          <label htmlFor="defaultImage">
                            <IconButton color="primary" aria-label="upload picture" component="span">
                              <PhotoCamera />
                            </IconButton>
                          </label>
                        </div>
                        <IconButton
                          aria-label="remove picture"
                          component="span"
                          onClick={() => {
                            setFieldValue('defaultImagePreviewUrl', '');
                            setFieldValue('defaultImage', '');
                          }}
                        >
                          <DeleteIcon color="error" />
                        </IconButton>
                      </div>
                      <div className={classes.imageError}>{touched.defaultImage && errors.defaultImage}</div>
                      <FieldArray
                        name="variants"
                        render={(arrayHelpers) => (
                          <div className={classes.variantsContainer}>
                            {values.variants.map((variant, variantIndex) => {
                              const isVariantReadOnly = registeredVariants?.some((e) => e === deviceData?.variants[variantIndex]?.color);

                              return (
                                <div className={classes.variantContainer} key={variantIndex}>
                                  <div>
                                    <Typography variant="body2">{`${variantIndex + 1}.`}</Typography>
                                  </div>
                                  <span className={classes.variantInput}>
                                    <InputField
                                      helperText={
                                        touched.variants &&
                                        deviceData?.variants?.length > 0 &&
                                        variant.color !== deviceData?.variants[variantIndex]?.color &&
                                        Boolean(isVariantReadOnly)
                                          ? 'This variant is registered and cannot be changed'
                                          : touched.variants
                                          ? errors.variants?.[variantIndex]?.color
                                          : ''
                                      }
                                      error={
                                        (touched.variants &&
                                          deviceData?.variants?.length > 0 &&
                                          variant.color !== deviceData?.variants[variantIndex]?.color &&
                                          Boolean(isVariantReadOnly)) ||
                                        (touched.variants && Boolean(errors.variants?.[variantIndex]?.color))
                                      }
                                      placeholder="description"
                                      name={`variants[${variantIndex}].color`}
                                      value={variant.color}
                                      onChange={(e) => {
                                        setFieldValue(`variants[${variantIndex}].color`, e.target.value);
                                      }}
                                      type="input"
                                    />
                                  </span>
                                  <input
                                    name="file"
                                    accept="image/*"
                                    multiple={false}
                                    onChange={async (event) => {
                                      const file = event.currentTarget.files[0];
                                      const base64Image = await convertFileToBase64(file);
                                      setFieldValue(`variants[${variantIndex}].image`, base64Image);

                                      setFieldValue(`variants[${variantIndex}].imagePreview`, URL.createObjectURL(file));
                                    }}
                                    className={classes.input}
                                    id={`variantImage-${variantIndex}`}
                                    type="file"
                                  />

                                  <div className={classes.variantImageContainer}>
                                    {variant.image?.includes('http') && (
                                      <img alt="" className={classes.image} src={`${variant.image}?${performance.now()}`} />
                                    )}
                                    {values.variants[variantIndex].imagePreview && (
                                      <div>
                                        <img alt="variant" className={classes.image} src={values.variants[variantIndex].imagePreview} />
                                      </div>
                                    )}
                                    <div>
                                      <div>
                                        <label htmlFor={`variantImage-${variantIndex}`}>
                                          <IconButton color="primary" aria-label="upload picture" component="span">
                                            <PhotoCamera />
                                          </IconButton>
                                        </label>

                                        <IconButton onClick={() => arrayHelpers.remove(variantIndex)}>
                                          <DeleteIcon color="error" type="button" />
                                        </IconButton>
                                      </div>

                                      <div className={classes.imageError}>{touched.variants && errors.variants?.[variantIndex]?.image}</div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                            <div className={classes.addVariantButton}>
                              <Button
                                variant="outlined"
                                color="primary"
                                size="small"
                                onClick={() =>
                                  arrayHelpers.push({
                                    color: '',
                                    image: values?.variants?.length === 0 ? values.defaultImage : '',
                                    imagePreview: values?.variants?.length === 0 ? values.defaultImagePreviewUrl : ''
                                  })
                                }
                              >
                                Add Variant
                              </Button>
                            </div>
                          </div>
                        )}
                      />
                    </CardContent>
                    <CardActions className={classes.actions}>
                      <Button type="submit" variant="contained" color="primary" disabled={isSubmitting}>
                        SUBMIT
                      </Button>
                      <Button
                        color="secondary"
                        onClick={() => {
                          handleReset();
                          setErrorImportDevice('');
                          setReadOnly(false);
                        }}
                      >
                        CLEAR
                      </Button>
                    </CardActions>
                  </Card>
                </form>
              );
            }}
          </Formik>
          {errorUpdateDevice && <ErrorAlert title={'Error updating device'} error={errorUpdateDevice} />}
        </div>
      ) : (
        <LoadingIndicator title="Retrieving device data" />
      )}
      {error && <ErrorAlert title={'Error retrieving device data'} error={error} />}
    </DashboardHOC>
  );
};

const DeviceEditPage = withFormik({
  mapPropsToValues: ({ devicesState, ...restProps }) => {
    const {
      match: { params }
    } = restProps;

    const { deviceData } = devicesState || {};

    const {
      defaultImage,
      tdpId,
      tdpIdentifier,
      type,
      variants,
      description,
      storeLink,
      brandMobileVideoTrailer,
      excludedFromWebsite,
      featuredOnWebsite
    } = deviceData || {};

    return {
      entityId: params?.brand,
      defaultImage: defaultImage || '',
      model: params.model,
      version: params.version,
      tdpId: tdpId || '',
      tdpIdentifier: tdpIdentifier || '',
      type: type || '',
      variants: variants || [],
      description: description || '',
      storeLink: storeLink || '',
      brandMobileVideoTrailer: brandMobileVideoTrailer || '',
      excludedFromWebsite,
      featuredOnWebsite
    };
  },

  handleSubmit: (values, { props, setSubmitting }) => {
    const { match } = props;

    const {
      defaultImage,
      model,
      version,
      tdpId,
      tdpIdentifier,
      type,
      variants,
      description,
      storeLink,
      brandMobileVideoTrailer,
      excludedFromWebsite,
      featuredOnWebsite
    } = values;

    const deviceModel = {
      entityId: match.params.oem,
      brand: match.params.oem,
      defaultImage,
      model: { name: model, version },
      nfcType: 'PASSIVE',
      tdpId: parseInt(tdpId),
      tdpIdentifier,
      type,
      variants: variants.length > 0 ? variants : null,
      description,
      storeLink,
      brandMobileVideoTrailer,
      excludedFromWebsite,
      featuredOnWebsite
    };

    props.createDevice(deviceModel);
    setSubmitting(false);
  }
})(Form);

Form.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      oem: PropTypes.string,
      brand: PropTypes.string,
      model: PropTypes.string,
      version: PropTypes.string
    })
  }).isRequired,

  classes: PropTypes.oneOfType([PropTypes.object]),
  values: PropTypes.oneOfType([PropTypes.object]),
  touched: PropTypes.oneOfType([PropTypes.object]),
  errors: PropTypes.oneOfType([PropTypes.object]),
  isSubmitting: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleReset: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  devicesState: PropTypes.shape({
    registeredVariants: PropTypes.array,
    errorUpdateDevice: PropTypes.string,
    error: PropTypes.string,
    createdDeviceData: PropTypes.object,
    errorCreateDevice: PropTypes.string,
    processing: PropTypes.bool,
    deviceData: PropTypes.oneOfType([PropTypes.object]),
    processingUpdateDevice: PropTypes.bool,
    updatedDeviceData: PropTypes.oneOfType([PropTypes.object])
  }),
  resetDevice: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  devicesState: state.devices
});

const mapDispatchToProps = (dispatch) => ({
  createDevice: (deviceModel) => dispatch(createDeviceAction(deviceModel)),
  updateDevice: (brand, model, version, deviceModel) => dispatch(updateDeviceAction(brand, model, version, deviceModel)),
  resetDevice: () => dispatch(resetCreatedDevice())
});

const ConnectedCreateForm = connect(mapStateToProps, mapDispatchToProps)(DeviceEditPage);

export default withStyles(styles)(ConnectedCreateForm);
